import React from "react";

interface IFieldsetContentProps {
    children?: JSX.Element | JSX.Element[];
}

export const FieldsetContent = React.memo((props: IFieldsetContentProps) => {
    const { children } = props;

    return <>{children}</>;
});

import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router";
import { Redirect } from "react-router-dom";
import { IState } from "../../shared/interfaces/state.interface";
import { selectVerificationCode, selectVerificationEmail } from "../../shared/redux/app.selectors";

export const ProtectedRoute = ({
    component: Component,
    verificationCode,
    verificationEmail,
    onEnter,
    ...rest
}: any) => {
    return (
        <Route
            {...rest}
            render={() => {
                if (!verificationCode || !verificationEmail) {
                    return <Redirect to="/verification" />;
                }

                return <Component />;
            }}
        />
    );
};

const mapStateToProps = (state: IState) => {
    return {
        verificationCode: selectVerificationCode(state),
        verificationEmail: selectVerificationEmail(state)
    };
};

export const ProtectedRouteWithStore = connect(mapStateToProps, null)(ProtectedRoute);

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { IState } from "../shared/interfaces/state.interface";
import { selectLoginUrl, selectSuccessFulRegistration } from "../shared/redux/app.selectors";
import { ReactComponent as Gothaer } from "../assets/images/gothaer.svg";
import { ReactComponent as Flixcheck } from "../assets/images/logo.svg";
import { lighten } from "polished";
import { Spinner } from "../components/shared/LoadingOverlay";

const CenteredView = styled.div`
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    min-height: calc(100vh - 52px);
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Brandings = styled.div`
    padding: 100px 0;

    @media screen and (min-width: 765px) {
        display: block;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    span {
        font-size: 48px;
        line-height: 1;
        color: var(--white);
        display: block;
        text-align: center;
        margin: 30px 0;

        @media screen and (min-width: 765px) {
            margin: 0 60px;
            display: inline-block;
        }
    }
`;

const ContentWrapper = styled.div`
    width: calc(100% - 64px);
    max-width: 700px;
    background-color: var(--white);
    padding: 30px;
    margin: 0 32px;
    text-align: center;

    h1 {
        font-size: 24px;
        color: var(--primary);
    }

    p {
        color: ${lighten(0.5, "#212529")};
        font-size: 12px;
    }
`;

const GothaerWrapper = styled.div`
    width: 265px;

    @media screen and (max-width: 765px) {
        display: block;
        width: 100%;
    }

    svg {
        @media screen and (min-width: 765px) {
            width: 100%;
            height: auto;
        }
    }
`;

const FlixcheckWrapper = styled.div`
    width: 265px;

    @media screen and (max-width: 765px) {
        display: block;
        width: 100%;
        text-align: center;
    }

    svg {
        @media screen and (min-width: 765px) {
            width: 100%;
            height: auto;
        }
    }
`;

const SpinnerWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 75px;
`;
interface IConfirmationProps {
    successFulRegistration: boolean;
    registrationDataEmail: string;
    loginUrl: string;
}

export const Confirmation = (props: IConfirmationProps) => {
    const { successFulRegistration, loginUrl } = props;
    const history = useHistory();

    useEffect(() => {
        if (!successFulRegistration) {
            history.push("/registration");
        }
    }, [history, successFulRegistration]);

    useEffect(() => {
        if (!loginUrl) {
            return;
        }

        const timeout = setTimeout(() => {
            window.location.href = loginUrl;
        }, 3000);

        return function cleanUp() {
            clearTimeout(timeout);
        };
    }, [loginUrl]);

    return (
        <CenteredView>
            <Brandings>
                <GothaerWrapper>
                    <Gothaer />
                </GothaerWrapper>
                <span>+</span>
                <FlixcheckWrapper>
                    <Flixcheck />
                </FlixcheckWrapper>
            </Brandings>
            <ContentWrapper>
                <h1>Ihre Registrierung war erfolgreich</h1>
                <SpinnerWrapper>
                    <Spinner stroke="#00718F" />
                </SpinnerWrapper>
                <p>Einen Augenblick bitte, Sie werden weitergeleitet...</p>
            </ContentWrapper>
        </CenteredView>
    );
};

const mapStateToProps = (state: IState) => {
    return {
        successFulRegistration: selectSuccessFulRegistration(state),
        loginUrl: selectLoginUrl(state)
    };
};

const mapDispatchToProps = () => {
    return {};
};

export const ConfirmationWithStore = connect(mapStateToProps, mapDispatchToProps)(Confirmation);

import React, { ReactNode } from "react";
import styled from "styled-components";

const Content = styled.div`
    padding: 24px;

    h2 {
        font-size: 32px;
        color: var(--primary);
        font-weight: 700;
    }

    ul {
        list-style-type: none;
        font-size: 16px;

        li {
            padding: 4px 0;
            line-height: 24px;

            div {
                display: flex;
                align-items: flex-start;

                svg {
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                }

                span {
                    flex: 1;
                }
            }

            ul {
                width: 100%;
                padding-left: 24px;
                padding-top: 4px;
            }
        }
    }
`;

interface FAQModalContentWrapperProps {
    children: ReactNode;
}

export function FAQModalContentWrapper(props: FAQModalContentWrapperProps): JSX.Element {
    return <Content>{props.children}</Content>;
}

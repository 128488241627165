import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { Section } from "../../shared/Section";
import { ReactComponent as ClipBoard } from "../../../assets/images/icons/clipboard.svg";
import { ReactComponent as Document } from "../../../assets/images/icons/document.svg";
import { ReactComponent as Signature } from "../../../assets/images/icons/signature.svg";

const StyledSection = styled(Section)`
    padding: 120px 0 0;
`;

const ExampleCol = styled(Col)`
    margin-bottom: 120px;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;

    svg {
        width: 64px;
        height: 64px;
        margin-right: 20px;
    }

    h3 {
        padding-top: 15px;
    }

    > div {
        flex: 1;
    }
`;

export const ExampleOfUseTeaser = () => {
    return (
        <StyledSection>
            <Row>
                <ExampleCol xs={12} lg={{ span: 8, offset: 2 }}>
                    <Document />
                    <div>
                        <h3>Schadenmeldung</h3>
                        <p>
                            Mit der Flixcheck-Vorlage „Schadenmeldung“ erfasst Ihr Kunde ganz
                            einfach die für Sie wichtigen Ereignisse eines Sach- oder KFZ-Schadens,
                            wie Fotos von Schäden oder Adressen der Geschädigten.
                        </p>
                    </div>
                </ExampleCol>
                <ExampleCol xs={12} lg={{ span: 8, offset: 2 }}>
                    <ClipBoard />
                    <div>
                        <h3>Jahresgespräch</h3>
                        <p>
                            Dank Flixcheck können Sie allen Kunden eine jährliche Abfrage zum Status
                            der Lebenssituation senden und so für Sie relevante
                            Versicherungspotentiale erkennen und anbieten.
                        </p>
                    </div>
                </ExampleCol>
                <ExampleCol xs={12} lg={{ span: 8, offset: 2 }}>
                    <Signature />
                    <div>
                        <h3>Vertragsunterschrift</h3>
                        <p>
                            Sie haben alle Daten Ihres Kunden für einen Vertragsabschluss, nur eines
                            fehlt: seine Unterschrift. Mit Flixcheck holen Sie eine digitale
                            Unterschrift Ihres Kunden innerhalb weniger Momente ein.
                        </p>
                    </div>
                </ExampleCol>
            </Row>
        </StyledSection>
    );
};

import React from "react";
import styled from "styled-components";

export enum OverlayBackground {
    Light,
    Dark
}

interface IOverlayProps {
    children: JSX.Element | JSX.Element[];
}

const OverlayDomEl = styled.div`
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 21, 29, 0.66);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Overlay = (props: IOverlayProps) => {
    const { children } = props;

    return <OverlayDomEl>{children}</OverlayDomEl>;
};

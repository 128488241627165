import styled from "styled-components";
import { darken } from "polished";
import { ButtonSpace } from ".";

export const StyledButton = styled.button`
    font-weight: 500;
    text-transform: uppercase;
    padding: 28px;
    flex-shrink: 0;
    cursor: pointer;
    border: 0;
    background-color: var(--tertiary);
    color: var(--white);

    &:hover {
        background-color: ${darken(0.1, "#ED706C")};
        cursor: pointer;
    }

    &:focus,
    &:active {
        outline: none;
    }

    &:disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    ${({ theme }) => {
        switch (theme.space) {
            case ButtonSpace.Small:
                return `
                    font-size: 18px;
                    padding: 14px 28px;
                `;
            case ButtonSpace.Big:
            default:
                return `
                    font-size: 24px;
                    padding: 28px 60px;
                `;
        }
    }}
`;

StyledButton.defaultProps = {
    theme: {
        space: 0
    }
};

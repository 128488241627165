import React from "react";
import styled from "styled-components";
import { BoxTeaser } from "../BoxTeaser/BoxTeaser";
import { lighten } from "polished";
import { IState } from "../../../shared/interfaces/state.interface";
import { selectAdditionalUserFee, selectBaseFee } from "../../../shared/redux/app.selectors";
import { connect } from "react-redux";
import { useCurrentDate } from "../../../shared/hooks/use-current-date";

const Teaser = styled(BoxTeaser)`
    margin-bottom: 32px;
    padding-top: 55px;

    h2 {
        font-size: 32px;
        color: var(--primary);
        font-weight: 700;

        small {
            display: block;
            font-size: 16px;
            font-weight: 400;
        }
    }

    p {
        font-size: 64px;
        font-weight: 700;
        margin-bottom: 24px;

        span {
            &:not(.fee) {
                font-size: 32px;
                color: ${lighten(0.5, "#212529")};
                vertical-align: text-top;
                margin-left: 5px;
            }

            &.fee {
                display: flex;
            }
        }

        abbr {
            font-size: 36px;
            color: ${lighten(0.5, "#212529")};
        }

        small {
            display: block;
            font-size: 16px;
            color: ${lighten(0.5, "#212529")};
            font-weight: 500;
        }

        &.hint {
            font-size: 12px;
            font-weight: 400;
            color: ${lighten(0.5, "#212529")};
            margin-top: 30px;
            margin-bottom: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
`;

interface ITariffTeaserProps {
    baseFee: string;
    additionalUserFee: string;
}

export const TariffTeaser = (props: ITariffTeaserProps) => {
    const { baseFee, additionalUserFee } = props;

    const { currentYear } = useCurrentDate();

    return (
        <Teaser>
            <h2>
                Gothaer-Tarif ab {currentYear}
                <small>12 Monate Laufzeit / 3 Monate Kündigungsfrist</small>
            </h2>
            <p>
                <span className="fee">
                    {baseFee}
                    <abbr>*</abbr>
                </span>
                <small>pro Account</small>
            </p>
            <p>
                <span className="fee">
                    {additionalUserFee}
                    <abbr>*</abbr>
                </span>
                <small>pro zusätzlichen User</small>
            </p>
            <p>
                <small>* brutto/Monat inkl. MwSt.</small>
            </p>
        </Teaser>
    );
};

const mapStateToProps = (state: IState) => {
    return {
        baseFee: selectBaseFee(state),
        additionalUserFee: selectAdditionalUserFee(state)
    };
};

export const TariffTeaserWithStore = connect(mapStateToProps, {})(TariffTeaser);

import { IAction } from "../../../shared/interfaces/action.interface";

export enum SubmitActionTypes {
    Enable = "enable",
    Disable = "disable"
}

interface ISubmitState {
    enabled: boolean;
}

export function enabledSubmitAction(): IAction {
    return {
        type: SubmitActionTypes.Enable
    };
}

export function disableSubmitAction(): IAction {
    return {
        type: SubmitActionTypes.Disable
    };
}

export function submitReducer(state: ISubmitState, action: IAction) {
    switch (action.type) {
        case SubmitActionTypes.Enable:
            return {
                ...state,
                enabled: true
            };
        case SubmitActionTypes.Disable:
            return {
                ...state,
                enabled: false
            };
        default:
            return state;
    }
}

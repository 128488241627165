import React, { useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { RegistrationFormWithStore } from "../components/Form/RegistrationForm";
import { IState } from "../shared/interfaces/state.interface";
import { getContractDetails } from "../shared/redux/app.api-middleware";
import {
    selectSuccessFulRegistration,
    selectVerificationCode,
    selectVerificationEmail
} from "../shared/redux/app.selectors";
import { ContainerWithMaxWidth } from "../components/shared/ContainerWithMaxWidth";
import { BoxTeaser } from "../components/Teaser/BoxTeaser/BoxTeaser";
import { TariffTeaserWithStore } from "../components/Teaser/TariffTeaser/TariffTeaser";
import { ConditionTeaserWithStore } from "../components/Teaser/ConditionTeaser/ConditionTeaser";
import { ReactComponent as CheckIcon } from "../assets/images/icons/check-circle.svg";
import { FaqTeaser } from "../components/Teaser/FaqTeaser/FaqTeaser";
import { RegularUsage } from "../components/shared/FAQ/RegularUsage";
import { FinancedWithStore } from "../components/shared/FAQ/Financed";
import { SpecialRules } from "../components/shared/FAQ/SpecialRules";
import { useCurrentDate } from "../shared/hooks/use-current-date";
import { CONFIG } from "../shared/config/config";

const BlueBoxTeaser = styled(BoxTeaser)`
    padding: 60px 40px;
    background-color: var(--primary);
    color: var(--white);
    margin-bottom: 32px;

    h2 {
        font-size: 64px;
        line-height: 1;

        span {
            display: block;
            font-size: 32px;
            margin-bottom: 20px;
        }
    }

    p {
        font-weight: 400;
        margin-bottom: 50px;

        strong {
            &.withAbbr {
                position: relative;
                display: inline-block;

                &:before {
                    content: "*";
                    position: absolute;
                    top: 0;
                    left: -15px;
                }
            }
        }
    }

    ul {
        list-style-type: none;

        &:not(:last-of-type) {
            margin-bottom: 50px;
        }

        li {
            display: flex;
            align-items: flex-start;
            flex-flow: row wrap;
            margin-bottom: 16px;

            span {
                margin-left: 16px;
                padding-top: 5px;
                flex: 1;
            }

            &.description {
                font-weight: 700;
                display: block;
                margin-bottom: 24px;
            }
        }
    }
`;

const ContainerWithMaxWidthInclSpaceTop = styled(ContainerWithMaxWidth)`
    padding-top: 64px;
    padding-bottom: 64px;
`;
interface IRegistrationProps {
    verificationCode: string;
    verificationEmail: string;
    successFulRegistration: boolean;
    getContractDetails: (email: string, code: string) => Promise<void>;
}

export const Registration = (props: IRegistrationProps) => {
    const { verificationCode, verificationEmail, successFulRegistration, getContractDetails } =
        props;

    const history = useHistory();
    const { currentYear } = useCurrentDate();

    useEffect(() => {
        if (!verificationCode || !verificationEmail) {
            return;
        }

        const fetchData = async () => {
            await getContractDetails(verificationEmail, verificationCode);
        };

        fetchData();
    }, [getContractDetails, verificationCode, verificationEmail]);

    useEffect(() => {
        if (successFulRegistration) {
            history.push("/confirmation");
        }
    }, [history, successFulRegistration]);

    return (
        <ContainerWithMaxWidthInclSpaceTop>
            <Row>
                <Col xs={12}>
                    <h1>Für Flixcheck registrieren</h1>
                </Col>
                <Col
                    xs={12}
                    sm={{ span: 10, offset: 1 }}
                    md={{ span: 8, offset: 2 }}
                    lg={{ span: 5, offset: 0 }}
                >
                    <BlueBoxTeaser>
                        <h2>
                            <span>Gothaer-Tarif {currentYear}</span>
                            <span>Wir übernehmen auch in {currentYear} Ihre Lizenzkosten.*</span>
                        </h2>
                        <p>
                            Die Gothaer Versicherung übernimmt bis zum 31.12.{currentYear} die
                            Lizenzkosten für Ihren Flixcheck Account und alle weiteren User.
                        </p>
                        <p>
                            <strong className="withAbbr">Vorausgessetzt</strong> Sie versenden{" "}
                            {CONFIG.checksPerYear} Checks pro Jahr (inkl. Einzel-, Sammel-und
                            Widget-Checks). Bei erreichen des Ziels erstatten wir im Januar{" "}
                            {currentYear + 1} die Lizenzgebühren.
                        </p>
                        <ul>
                            <li className="description">Für Sie inklusive</li>
                            <li>
                                <CheckIcon />
                                <span>Lizenzgebühren</span>
                            </li>
                            <li>
                                <CheckIcon />
                                <span>Check-Flatrate</span>
                            </li>
                            <li>
                                <CheckIcon />
                                <span>Sammelcheck Add-On</span>
                            </li>
                            <li>
                                <CheckIcon />
                                <span>100 Frei-SMS pro Account / Monat</span>
                            </li>
                            <li>
                                <CheckIcon />
                                <span>
                                    PDF-Adapter für die Check-Antwort direkt in Ihr E-Mail Postfach
                                </span>
                            </li>
                        </ul>
                    </BlueBoxTeaser>
                    <TariffTeaserWithStore />
                    <ConditionTeaserWithStore />
                    <FaqTeaser
                        buttonContent={
                            <span>
                                Regelmäßige Flixcheck-Nutzung
                                <br />
                                bedeutet ...
                            </span>
                        }
                        modalContent={<RegularUsage />}
                    />
                    <FaqTeaser
                        buttonContent={<span>Was wird finanziert?</span>}
                        modalContent={<FinancedWithStore />}
                    />
                    <FaqTeaser
                        buttonContent={
                            <span>
                                Sonderregeln &
                                <br />
                                weitere Informationen
                            </span>
                        }
                        modalContent={<SpecialRules />}
                    />
                </Col>
                <Col
                    xs={12}
                    sm={{ span: 10, offset: 1 }}
                    md={{ span: 8, offset: 2 }}
                    lg={{ span: 7, offset: 0 }}
                >
                    <RegistrationFormWithStore />
                </Col>
            </Row>
        </ContainerWithMaxWidthInclSpaceTop>
    );
};

const mapStateToProps = (state: IState) => {
    return {
        verificationCode: selectVerificationCode(state),
        verificationEmail: selectVerificationEmail(state),
        successFulRegistration: selectSuccessFulRegistration(state)
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getContractDetails: (email: string, code: string) =>
            dispatch(getContractDetails(email, code))
    };
};

export const RegistrationWithStore = connect(mapStateToProps, mapDispatchToProps)(Registration);

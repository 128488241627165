import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { StyledLink } from "../../shared/Button/StyledLink";

const LeftColumn = styled(Row)`
    background-color: var(--secondary);

    iframe {
        width: 100%;
    }
`;

const RightColumn = styled(Row)`
    background-color: #10738d;
    padding: 70px;
    color: var(--white);
    height: 100%;
`;

const StyledLinkWithMargin = styled(StyledLink)`
    margin: 50px auto;
`;

export const VideoTeaser = () => {
    return (
        <Row>
            <Col xs={12} lg={6}>
                <LeftColumn>
                    <iframe
                        width="560"
                        height="400"
                        src="https://www.youtube.com/embed/Z210jdzK7Pc"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Lernen Sie Flixcheck kennen"
                    ></iframe>
                    <StyledLinkWithMargin to="/verification">
                        Jetzt Registrieren
                    </StyledLinkWithMargin>
                </LeftColumn>
            </Col>
            <Col xs={12} lg={6}>
                <RightColumn>
                    <h2>Ihr Tool für verflixt einfachen Kundendialog.</h2>
                    <p>
                        Mit Flixcheck versenden Sie individuelle, digitale Formulare per SMS, E-Mail
                        oder Messenger und erhalten schon nach wenigen Klicks z.B.
                        Kontaktinformationen, Unterschriften, Bankverbindungen oder Fotos Ihrer
                        Kunden zurück. Und das Ganze funktioniert ohne Download oder Installation.
                    </p>
                </RightColumn>
            </Col>
        </Row>
    );
};

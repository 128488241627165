import React from "react";
import styled from "styled-components";
import CheckIcon from "../../../assets/images/icons/check.svg";

const CheckboxContainer = styled.div`
    width: 100%;
`;

const Input = styled.input`
    position: absolute;
    visibility: hidden;
    overflow: hidden;
    width: 0;
    height: 0;

    &:checked {
        + label {
            &:after {
                content: "";
                background-image: url("${CheckIcon}");
                background-repeat: no-repeat;
                background-size: cover;
                width: 15px;
                height: 12px;
                display: block;
                position: absolute;
                top: 2px;
                left: 2px;
            }
        }
    }

    &:disabled {
        + label {
            &:before {
                border: 2px solid var(--grey);
            }
        }
    }
`;

const CheckboxLabel = styled.label`
    position: relative;
    display: flex !important;
    align-items: flex-start;
    cursor: pointer;
    margin-bottom: 8px;

    &:before {
        content: "";
        display: block;
        margin-top: 2px;
        width: 12px;
        height: 12px;
        border: 2px solid var(--primary);
        margin-right: 8px;
        flex-shrink: 0;
    }
`;

export const CheckboxInput = React.forwardRef(
    ({ children, name, id, label, value, onChange, ...rest }: any, ref: any) => {
    return (
        <CheckboxContainer>
            <Input
                {...rest}
                ref={ref}
                id={id}
                type="checkbox"
                name={name}
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
            />
            <CheckboxLabel htmlFor={id}>
                <span>
                    {children}
                </span>
            </CheckboxLabel>
        </CheckboxContainer>
    );
})

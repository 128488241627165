import React from "react";
import { StyledInput } from "./StyledInput";

export const EmailInput = React.forwardRef(({ readOnly, placeHolder, ...rest }: any, ref: any) => {
    return (
        <StyledInput
            ref={ref}
            type="email"
            readOnly={readOnly}
            placeholder={placeHolder}
            {...rest}
        />
    );
});

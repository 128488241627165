import React, { ReactNode } from "react";
import styled from "styled-components";
import { ReactComponent as ArrowSingleIcon } from "../../../assets/images/icons/arrow_single.svg";
import { useModal } from "../../../shared/hooks/use-modal";
import { Modal } from "../../shared/Modal";

const TeaserButton = styled.button`
    border: 0;
    background-color: white;
    padding: 25px;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 24px;
    color: var(--primary);
    text-align: left;
    cursor: pointer;
    margin-bottom: 12px;

    span {
        margin-right: 48px;
        flex: 1;
    }

    svg {
        transform: translateX(0);
        transition: transform 0.15s linear;
    }

    &:hover {
        svg {
            transform: translateX(8px);
        }
    }
`;

interface FaqTeaserProps {
    buttonContent: ReactNode;
    modalContent: JSX.Element;
}

export const FaqTeaser = (props: FaqTeaserProps) => {
    const { modalRef, isVisible, toggleVisibility } = useModal();

    return (
        <>
            <TeaserButton onClick={toggleVisibility}>
                {props.buttonContent}
                <ArrowSingleIcon />
            </TeaserButton>
            <Modal ref={modalRef} show={isVisible} onClose={toggleVisibility}>
                {props.modalContent}
            </Modal>
        </>
    );
};

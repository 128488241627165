import styled from "styled-components";

export const StyledInput = styled.input`
    background-color: var(--white);
    padding: 12px var(--size-16);
    width: 100%;
    color: #00718f;
    border: 1px solid #00718f;

    ::placeholder {
        color: var(--grey);
    }

    &:read-only {
        border: 1px solid var(--grey);
    }

    &:focus {
        outline: none;
    }
`;

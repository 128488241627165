import React, { useEffect, useReducer, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Button, ButtonSpace, ButtonType } from "../shared/Button";
import { EmailInput, Fieldset, Form, FormElement, PasswordInput } from "../shared/Form";
import { yupResolver } from "@hookform/resolvers/yup";
import { VerificationValidationSchema } from "../../shared/validation-schema/verification.validation-schema";
import styled from "styled-components";
import { disableSubmitAction, enabledSubmitAction, submitReducer } from "./reducer/submit.reducer";
import { IState } from "../../shared/interfaces/state.interface";
import {
    selectVerificationCode,
    selectVerificationCodeRequested
} from "../../shared/redux/app.selectors";
import { useHistory } from "react-router-dom";
import { requestCode, verify } from "../../shared/redux/app.api-middleware";
import { darken } from "polished";

const COUNTDOWN_SECONDS = 30;

const SubmitWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

const Countdown = styled.p`
    width: 100%;
    margin-top: 20px;
    font-size: 12px;
    text-align: center;
    color: var(--grey);
`;

const ReSendButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid var(--light-grey);

    div {
        width: 100%;
    }
`;

const InlineButton = styled(Button)`
    padding: 0;
    background-color: transparent;
    color: var(--tertiary);

    &:hover {
        color: ${darken(0.1, "#ED706C")};
        background-color: transparent;
    }
`;
interface IVerificationFormData {
    email: string;
    code: string;
}

interface IVerificationFormProps {
    verificationCode: string;
    verificationCodeRequested: boolean;
    verify: (email: string, code: string) => Promise<void>;
    requestCode: (email: string) => Promise<void>;
}

export const VerificationForm = (props: IVerificationFormProps) => {
    const { verificationCode, verificationCodeRequested, verify, requestCode } = props;
    const history = useHistory();
    const methods = useForm<IVerificationFormData>({
        mode: "all",
        defaultValues: {
            email: "",
            code: ""
        },
        resolver: yupResolver(VerificationValidationSchema)
    });
    const [state, dispatch] = useReducer(submitReducer, { enabled: false });
    const { enabled } = state;

    const { control, formState, errors, watch } = methods;
    const { isValid } = formState;
    const { email } = watch();

    const [seconds, setSeconds] = useState<number>(COUNTDOWN_SECONDS);

    useEffect(() => {
        if (!!verificationCodeRequested || seconds === COUNTDOWN_SECONDS) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    clearInterval(interval);
                }
            }, 1000);

            return () => {
                if (!!interval) {
                    clearInterval(interval);
                }
            };
        }
    }, [seconds, verificationCodeRequested]);

    useEffect(() => {
        if (isValid) {
            dispatch(enabledSubmitAction());
        } else {
            dispatch(disableSubmitAction());
        }
    }, [isValid]);

    useEffect(() => {
        if (!!verificationCode) {
            history.push("/registration");
        }
    }, [history, verificationCode]);

    const onCodeRequest = async () => {
        await requestCode(email);
    };

    const onCodeReRequest = async () => {
        setSeconds(COUNTDOWN_SECONDS);

        await requestCode(email);
    };

    const onSubmit = async ({ email, code }: IVerificationFormData) => {
        dispatch(disableSubmitAction());

        await verify(email, code);
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={onSubmit}>
                <Fieldset>
                    <Fieldset.Content>
                        <FormElement label="Ihre Gothaer E-Mail Adresse" error={errors.email}>
                            <Controller
                                control={control}
                                name="email"
                                readOnly={verificationCodeRequested && !!email}
                                placeHolder="max.mustermann@gothaer.de"
                                as={EmailInput}
                            />
                        </FormElement>
                        {verificationCodeRequested ? (
                            <>
                                <FormElement label="Ihr Zugangscode" error={errors.code}>
                                    <Controller control={control} name="code" as={PasswordInput} />
                                </FormElement>
                            </>
                        ) : (
                            <></>
                        )}
                        <SubmitWrapper>
                            {verificationCodeRequested ? (
                                <Button
                                    label="Einloggen"
                                    disabled={!enabled}
                                    space={ButtonSpace.Small}
                                    type={ButtonType.Submit}
                                />
                            ) : (
                                <Button
                                    label="Code anfordern"
                                    disabled={!email}
                                    space={ButtonSpace.Small}
                                    type={ButtonType.Button}
                                    onClick={onCodeRequest}
                                />
                            )}
                        </SubmitWrapper>
                        {verificationCodeRequested ? (
                            <ReSendButtonWrapper>
                                <div>
                                    {seconds !== 0 ? (
                                        <Countdown>
                                            Sie könnnen in {seconds} Sekunden einen neuen
                                            Zugangscode anfordern...
                                        </Countdown>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                {seconds === 0 ? (
                                    <InlineButton
                                        label="Zugangscode erneut anfordern"
                                        disabled={!email}
                                        space={ButtonSpace.Small}
                                        type={ButtonType.Button}
                                        onClick={onCodeReRequest}
                                    />
                                ) : (
                                    <></>
                                )}
                            </ReSendButtonWrapper>
                        ) : (
                            <></>
                        )}
                    </Fieldset.Content>
                </Fieldset>
            </Form>
        </FormProvider>
    );
};

const mapStateToProps = (state: IState) => {
    return {
        verificationCode: selectVerificationCode(state),
        verificationCodeRequested: selectVerificationCodeRequested(state)
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        verify: (email: string, code: string) => dispatch(verify(email, code)),
        requestCode: (email: string) => dispatch(requestCode(email))
    };
};

export const VerificationFormWithStore = connect(
    mapStateToProps,
    mapDispatchToProps
)(VerificationForm);

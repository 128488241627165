import { IRegistrationData } from "../interfaces/registration-data.interface";
import { IState } from "../interfaces/state.interface";
import { createSelector } from "reselect";
import { IContractData } from "../interfaces/contract-data.interface";
import * as FormatHelper from "../helper/format.helper";

export const selectVerification = (
    state: IState
): { code?: string; email?: string; codeRequested?: boolean } => state?.verification || {};

export const selectVerificationCode = createSelector(
    [selectVerification],
    (verification: { code?: string; email?: string; codeRequested?: boolean }): string => {
        return verification?.code || "";
    }
);

export const selectVerificationEmail = createSelector(
    [selectVerification],
    (verification: { code?: string; email?: string; codeRequested?: boolean }): string => {
        return verification?.email || "";
    }
);

export const selectVerificationCodeRequested = createSelector(
    [selectVerification],
    (verification: { code?: string; email?: string; codeRequested?: boolean }): boolean => {
        return verification?.codeRequested || false;
    }
);

export const selectLoadingStatus = (state: IState): boolean => state?.isLoading || false;

export const selectSuccessFulRegistration = (state: IState): boolean =>
    state?.successFulRegistration || false;

export const selectLoginUrl = (state: IState): string => state?.loginUrl || "";

export const selectContractData = (state: IState): IContractData =>
    state?.contractData || {
        baseFee: 0,
        additionalUserFee: 0,
        smsIncl: 0,
        smsFee: 0,
        batchIncl: 0,
        batchFee: 0
    };

export const selectBaseFee = createSelector(
    [selectContractData],
    (contractData: IContractData): string => {
        return FormatHelper.formatCurrency((contractData?.baseFee || 0) * 1.19);
    }
);

export const selectAdditionalUserFee = createSelector(
    [selectContractData],
    (contractData: IContractData): string => {
        return FormatHelper.formatCurrency(
            +((contractData?.additionalUserFee || 0) * 1.19).toFixed(3)
        );
    }
);

export const selectSmsIncl = createSelector(
    [selectContractData],
    (contractData: IContractData): number => {
        return contractData?.smsIncl || 0;
    }
);

export const selectSmsFee = createSelector(
    [selectContractData],
    (contractData: IContractData): string => {
        return FormatHelper.formatCurrency((contractData?.smsFee || 0) * 1.19);
    }
);

export const selectBatchIncl = createSelector(
    [selectContractData],
    (contractData: IContractData): number => {
        return contractData?.batchIncl || 0;
    }
);

export const selectBatchFee = createSelector(
    [selectContractData],
    (contractData: IContractData): string => {
        return FormatHelper.formatCurrency((contractData?.batchFee || 0) * 1.19);
    }
);

export const selectRegistrationData = (state: IState): IRegistrationData =>
    state?.registrationData || {
        acceptedTermsAndPrivacyPolicy: false,
        acceptedOrderProcessingContract: false,
        email: "",
        password: "",
        company: {
            name: "",
            street: "",
            postcode: "",
            city: "",
            phone: "",
            website: ""
        },
        contactPerson: {
            gender: "",
            firstname: "",
            lastname: ""
        },
        billing: {
            accountOwner: "",
            iban: "",
            bic: ""
        },
        agencyCode: "",
        rdNumber: ""
    };

export const selectPreFillData = (state: IState): Partial<IRegistrationData> =>
    state?.preFillData || {};

import { AxiosRequestConfig } from "axios";
import { ApiConfig } from "../config/api.config";

export async function performGetRequest(path: string, config: AxiosRequestConfig): Promise<any> {
    return ApiConfig.get(path, config);
}

export async function performPostRequest(
    path: string,
    data: any,
    config?: AxiosRequestConfig
): Promise<any> {
    return ApiConfig.post(path, data, config);
}

import React from "react";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import { ContainerWithMaxWidth } from "../ContainerWithMaxWidth";

const Footer = styled.footer`
    height: 52px;
    width: 100%;
    background-color: var(--secondary);
`;

const FooterNavigaiton = styled.ul`
    display: flex;
    padding: 19px 16px;
    font-size: 12px;
    list-style-type: none;

    li {
        a {
            color: var(--primary);
            text-decoration: none;
        }

        &:not(:last-child) {
            margin-right: 16px;
        }
    }
`;

export const StickyFooter = () => {
    return (
        <Footer>
            <ContainerWithMaxWidth>
                <Row>
                    <FooterNavigaiton>
                        <li>
                            <a
                                href="https://www.flixcheck.de/impressum/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Impressum
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.flixcheck.de/datenschutz/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Datenschutz
                            </a>
                        </li>
                    </FooterNavigaiton>
                </Row>
            </ContainerWithMaxWidth>
        </Footer>
    );
};

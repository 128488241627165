import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { ReactComponent as Gothaer } from "../../assets/images/gothaer.svg";

const StyledContainer = styled(Container)`
    max-width: 1440px;
`;

const NavContainer = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
`;

const StyledNav = styled.nav`
    display: flex;
    justify-content: center;
    padding: 31px 0;

    svg {
        width: 185px;
        height: auto;
    }
`;

export const NavigationSimple = () => {
    return (
        <NavContainer>
            <StyledContainer fluid>
                <Row>
                    <Col xs={12}>
                        <StyledNav>
                            <Gothaer />
                        </StyledNav>
                    </Col>
                </Row>
            </StyledContainer>
        </NavContainer>
    );
};

import React from "react";
import styled from "styled-components";
import { FieldsetContent } from './FieldsetContent';
import { FieldsetLegend } from './FieldsetLegend';
interface IFieldsetProps {
    children?: JSX.Element | JSX.Element[];
}

const FieldsetWrapper = styled.fieldset`
    width: 100%;
    border: 0;
    margin: 0;
    padding: 0;

    &:not(:last-of-type) {
        margin-bottom: 48px;
    }
`;

const Fieldset = (props: IFieldsetProps) => {
    const { children } = props;

    return <FieldsetWrapper>{children}</FieldsetWrapper>;
};

Fieldset.Legend = FieldsetLegend;
Fieldset.Content = FieldsetContent;

export { Fieldset };

import React, { useMemo } from "react";
import { ReactComponent as ArrowDoubleIcon } from "../../../assets/images/icons/arrow_double.svg";
import { FAQModalContentWrapper } from "./FAQModalContentWrapper";
import { useCurrentDate } from "../../../shared/hooks/use-current-date";
import { CONFIG } from "../../../shared/config/config";

export function RegularUsage(): JSX.Element {
    const { currentYear } = useCurrentDate();

    return (
        <FAQModalContentWrapper>
            <h2>Regelmäßige Flixcheck-Nutzung bedeutet ...</h2>
            <ul>
                <li>
                    <div>
                        <ArrowDoubleIcon />
                        <span>
                            {CONFIG.checksPerYear} Checks pro Jahr ({CONFIG.checksPerMonth} Checks
                            pro Monat) oder anteilig je nach Beginn des Accounts, also z. B.{" "}
                            {CONFIG.checkPerHalfYear} Checks bei Beginn des Accounts zum 01.07.
                        </span>
                    </div>
                </li>
                <li>
                    <div>
                        <ArrowDoubleIcon />
                        <span>
                            Gezählt werden Sammel-, Widget-und Einzelcheck (Gebühren werden nicht
                            übernommen, es geht lediglich um die Anzahl der erstellten Checks)
                        </span>
                    </div>
                </li>
                <li>
                    <div>
                        <ArrowDoubleIcon />
                        <span>
                            {CONFIG.checksPerYear} Checks können verteilt über das ganze Jahr und
                            auch innerhalb eines Tages/Monats erstellt werden
                        </span>
                    </div>
                </li>
                <li>
                    <div>
                        <ArrowDoubleIcon />
                        <span>
                            Agenturen gehen in Vorkasse und zum 31.12.{currentYear} erfolgt eine
                            Auswertung
                        </span>
                    </div>
                </li>
                <li>
                    <div>
                        <ArrowDoubleIcon />
                        <span>
                            Agenturen, die das Ziel erreicht haben, bekommen im Januar 2024
                            rückwirkend die monatlichen Lizenzkosten auf das Agenturkonto
                            gutgeschrieben. Wir überweisen Ihnen somit im Januar {currentYear + 1}{" "}
                            die Flixcheck-Jahresgebühr von {currentYear}, wenn Sie zum 31.12.
                            {currentYear} das Check-Ziel von {CONFIG.checksPerYear} erreicht haben
                        </span>
                    </div>
                </li>
            </ul>
        </FAQModalContentWrapper>
    );
}

import React from "react";

interface ISectionProps {
    className?: string;
    children?: any;
}

export const Section = (props: ISectionProps) => {
    const { className, children } = props;

    return <section className={className}>{children}</section>;
};

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Spinner } from ".";
import { IState } from "../../../shared/interfaces/state.interface";
import { selectLoadingStatus } from "../../../shared/redux/app.selectors";
import { Overlay } from "../Overlay";

interface ILoadingOverlayProps {
    isLoading: boolean;
}

export const LoadingOverlay = (props: ILoadingOverlayProps) => {
    const { isLoading } = props;
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowLoadingIndicator(isLoading);
        }, 200);

        return () => {
            clearTimeout(timeout);
        };
    }, [isLoading]);

    return showLoadingIndicator ? (
        <Overlay>
            <Spinner />
        </Overlay>
    ) : null;
};

const mapStateToProps = (state: IState) => {
    return {
        isLoading: selectLoadingStatus(state)
    };
};

export const LoadingOverlayWithStore = connect(mapStateToProps, {})(LoadingOverlay);

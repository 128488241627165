import { Dispatch } from "redux";
import {
    SetCodeRequestedAction,
    setContractData,
    setLoadingStatusAction,
    setLoginUrlAction,
    setPreFillDataAction,
    setSuccessFulRegistration,
    setVerificationDataAction
} from "./app.actions";
import * as httpService from "../services/http.service";
import { ApiEndpoint } from "../enums/api-endpoint.enum";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";
import { IRegistrationData } from "../interfaces/registration-data.interface";

export function requestCode(email: string) {
    return function dispatch(dispatch: Dispatch) {
        dispatch(setLoadingStatusAction(true));

        httpService
            .performPostRequest(ApiEndpoint.Verification, { data: { email } })
            .then(() => {
                toast(`Sie erhalten in Kürze eine E-Mail mit dem benötigten Verifizierungscode.`, {
                    className: "fc-toast success"
                });
                dispatch(SetCodeRequestedAction());
            })
            .catch((error) => handleError(error.response))
            .finally(() => dispatch(setLoadingStatusAction(false)));
    };
}

export function verify(email: string, code: string) {
    return function dispatch(dispatch: Dispatch) {
        dispatch(setLoadingStatusAction(true));

        try {
            httpService
                .performGetRequest(ApiEndpoint.Verification, { params: { code, email } })
                .then((response: AxiosResponse) => {
                    const { valid, prefill, meta } = response.data?.data || {
                        prefill: {},
                        meta: {
                            agencyCode: "",
                            rdNumber: ""
                        },
                        valid: false,
                        used: true
                    };

                    if (!valid) {
                        toast(
                            `Der von Ihnen angegebene Code "${code}" in Kombination mit der angegebenen Email "${email}" ist nicht valide.`,
                            {
                                className: "fc-toast error"
                            }
                        );
                        dispatch(setVerificationDataAction("", ""));
                        return;
                    }

                    toast(
                        `Der von Ihnen eingegebene Code: "${code}" wird nun für die Registrierung verwendet.`,
                        {
                            className: "fc-toast success"
                        }
                    );

                    dispatch(setVerificationDataAction(email, code));
                    dispatch(
                        setPreFillDataAction({
                            ...prefill,
                            agencyCode: meta?.agencyCode || "",
                            rdNumber: meta?.rdNumber || ""
                        })
                    );
                })
                .catch((error) => handleError(error.response))
                .finally(() => dispatch(setLoadingStatusAction(false)));
        } catch (error) {
            console.error(error);
        }
    };
}

export function getContractDetails(email: string, code: string) {
    return function dispatch(dispatch: Dispatch) {
        dispatch(setLoadingStatusAction(true));

        try {
            httpService
                .performGetRequest(ApiEndpoint.ContractDetails, {
                    auth: { username: email, password: code }
                })
                .then((response: AxiosResponse) => {
                    const data = response.data?.data || {};

                    dispatch(setContractData(data));
                })
                .catch((error) => handleError(error.response))
                .finally(() => dispatch(setLoadingStatusAction(false)));
        } catch (error) {
            console.error(error);
        }
    };
}

export function register(data: IRegistrationData, email: string, code: string) {
    return async function dispatch(dispatch: Dispatch) {
        dispatch(setLoadingStatusAction(true));

        try {
            httpService
                .performPostRequest(
                    `${ApiEndpoint.Registration}`,
                    { data },
                    {
                        auth: { username: email, password: code }
                    }
                )
                .then((response: AxiosResponse) => {
                    const responseData = response.data?.data;

                    toast("Vielen Dank. Ihre Registrierung war erfolgreich.", {
                        className: "fc-toast success"
                    });
                    dispatch(setSuccessFulRegistration(true, data));
                    dispatch(setLoginUrlAction(responseData?.loginUrl || ""));
                })
                .catch((error) => handleError(error.response))
                .finally(() => dispatch(setLoadingStatusAction(false)));
        } catch (error) {
            console.error(error);
        }
    };
}

function handleError(response: AxiosResponse) {
    const errorMessage = response?.data?.error?.message;
    const toastMessage = !!errorMessage
        ? errorMessage
        : "Ein Fehler ist aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.";

    toast(toastMessage, {
        className: "fc-toast error"
    });
}

import React from "react";
import { StyledButton } from "./StyledButton";

export enum ButtonType {
    Button = "button",
    Submit = "submit"
}

export enum ButtonSpace {
    Small,
    Big
}

interface IButtonProps {
    label: string;
    className?: string;
    type?: ButtonType;
    space?: ButtonSpace;
    disabled?: boolean;
    onClick?: () => void;
}

export const Button = (props: IButtonProps) => {
    const { label, className, type, space, disabled, onClick } = props;

    return (
        <StyledButton
            className={className}
            theme={space}
            type={!!type ? type : ButtonType.Button}
            disabled={disabled}
            onClick={() => onClick?.()}
        >
            {label}
        </StyledButton>
    );
};

import React from "react";
import styled from "styled-components";
import { useClickOutside } from "../../shared/hooks/use-click-outside";
import { useEscapeKey } from "../../shared/hooks/use-escape-key";
import { Overlay } from "./Overlay";
import { ReactComponent as Cancel } from "../../assets/images/icons/cancel.svg";

const ModalContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 960px;
    height: auto;
    background-color: var(--white);

    .title-container {
        position: relative;
        padding: 16px;
        text-align: center;
        font-weight: bold;
    }

    .inner-container {
        max-height: 600px;
        overflow: auto;
        padding: 16px;
        -webkit-overflow-scrolling: touch;
    }
`;

const CloseBtn = styled.button`
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: transparent;
    cursor: pointer;
    border: none;

    svg {
        width: 20px;
        height: 20px;
    }

    &:focus {
        outline: none;
    }
`;

interface IModalProps {
    title?: string;
    show?: boolean;
    children?: JSX.Element;
    onClose?: () => void;
}

export const Modal = React.forwardRef((props: IModalProps, ref: any) => {
    const { onClose, title, show, children } = props;

    useEscapeKey(() => {
        if (show) {
            onClose?.();
        }
    });
    useClickOutside(ref, () => onClose?.());

    return show ? (
        <Overlay>
            <ModalContainer ref={ref}>
                {!!title?.length ? <div className="title-container">{title}</div> : null}
                <div className="inner-container">{children}</div>
                <CloseBtn type="button" onClick={() => onClose?.()}>
                    <Cancel />
                </CloseBtn>
            </ModalContainer>
        </Overlay>
    ) : null;
});

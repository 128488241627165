import { useMemo } from "react";

export interface CurrentDateHook {
    currentYear: number;
}

export function useCurrentDate(): CurrentDateHook {
    const currentYear = useMemo(() => {
        const now = new Date();

        return now.getFullYear();
    }, []);

    return {
        currentYear
    };
}

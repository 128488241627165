import { IAction } from "../interfaces/action.interface";
import { IState } from "../interfaces/state.interface";
import { AppActionTypes } from "./app.action-types";

const initialState: IState = {
    verification: undefined,
    contractData: undefined,
    successFulRegistration: undefined,
    registrationData: undefined,
    isLoading: undefined,
    loginUrl: undefined,
    preFillData: undefined
};

export function appReducer(state: IState = initialState, action: IAction): IState {
    switch (action.type) {
        case AppActionTypes.SetVerificationData:
            return {
                ...state,
                verification: action.payload
            };
        case AppActionTypes.SetContractData:
            return {
                ...state,
                contractData: action.payload
            };
        case AppActionTypes.SetLoadingStatus:
            return {
                ...state,
                isLoading: action.payload
            };
        case AppActionTypes.SetSuccessFulRegistration:
            return {
                ...state,
                successFulRegistration: action.payload.successFulRegistration,
                registrationData: action.payload.registrationData
            };
        case AppActionTypes.SetLoginUrl:
            return {
                ...state,
                loginUrl: action.payload
            };
        case AppActionTypes.SetCodeRequested:
            return {
                ...state,
                verification: {
                    ...state.verification,
                    codeRequested: true
                }
            };
        case AppActionTypes.SetPreFillData:
            return {
                ...state,
                preFillData: action.payload
            };
        default:
            return state;
    }
}

import React from "react";
import { lighten } from "polished";
import styled from "styled-components";
import { BoxTeaser } from "../BoxTeaser/BoxTeaser";
import { IState } from "../../../shared/interfaces/state.interface";
import {
    selectBatchFee,
    selectBatchIncl,
    selectSmsFee,
    selectSmsIncl
} from "../../../shared/redux/app.selectors";
import { connect } from "react-redux";

const Teaser = styled(BoxTeaser)`
    margin-bottom: 32px;
    padding-top: 55px;

    h2 {
        font-size: 32px;
        color: var(--primary);
        font-weight: 700;

        span {
            color: ${lighten(0.5, "#212529")};
        }
    }

    p {
        font-weight: 500;

        span {
            font-weight: 400;

            &:not(.dark) {
                color: ${lighten(0.5, "#212529")};
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &.hint {
            font-size: 16px;
            color: ${lighten(0.5, "#212529")};
        }
    }
`;

interface IConditionTeaserProps {
    smsIncl: number;
    smsFee: string;
    batchIncl: number;
    batchFee: string;
}

export const ConditionTeaser = (props: IConditionTeaserProps) => {
    const { smsIncl, smsFee, batchFee } = props;

    return (
        <Teaser>
            <h2>Ihr persönlicher Anteil</h2>
            <p>
                Checkversand per E-Mail
                <br />
                <span className="dark">kostenlos</span>
            </p>
            <p>
                Checkversand per SMS
                <br />
                <span className="dark">{smsIncl} SMS / Monat inkl.</span>
                <br />
                <span>jede weitere SMS: {smsFee}</span>
                <br />
            </p>
            <p>
                Sammelcheck
                <br />
                <span className="dark">pro Check: {batchFee}</span>
                <br />
                <span>zzgl. etwaige SMS Kosten</span>
            </p>
            <p>
                Add-Ons aus dem Shop
                <br />
                <span className="dark">Gekaufte Erweiterungen sind selbst zu tragen</span>
            </p>
        </Teaser>
    );
};

const mapStateToProps = (state: IState) => {
    return {
        smsIncl: selectSmsIncl(state),
        smsFee: selectSmsFee(state),
        batchIncl: selectBatchIncl(state),
        batchFee: selectBatchFee(state)
    };
};

export const ConditionTeaserWithStore = connect(mapStateToProps, {})(ConditionTeaser);

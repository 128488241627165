import React from "react";
import HeaderImage from "../../assets/images/header-background.jpg";
import styled from "styled-components";
import { Container, Row } from "react-bootstrap";

const StyledImage = styled.div`
    width: 100%;
    height: 340px;
    background-image: url(${HeaderImage});
    background-size: cover;
    background-position: center;
`;

const StyledContainer = styled(Container)`
    max-width: 1440px;
`;

export const Header = () => {
    return (
        <header>
            <StyledContainer fluid>
                <Row>
                    <StyledImage />
                </Row>
            </StyledContainer>
        </header>
    );
};

import React from "react";
import styled from "styled-components";

interface IFieldsetLegendProps {
    children: JSX.Element | JSX.Element[];
}

const Legend = styled.legend`
    width: 100%;
    margin-bottom: 32px;
    
    h4 {
        font-size: 24px;
    }
`;

export const FieldsetLegend = React.memo((props: IFieldsetLegendProps) => {
    const { children } = props;

    return <Legend>{children}</Legend>;
});

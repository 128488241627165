import React from "react";
import { connect } from "react-redux";
import { ReactComponent as ArrowDoubleIcon } from "../../../assets/images/icons/arrow_double.svg";
import { IState } from "../../../shared/interfaces/state.interface";
import { selectAdditionalUserFee, selectBaseFee } from "../../../shared/redux/app.selectors";
import { FAQModalContentWrapper } from "./FAQModalContentWrapper";

interface FinancedProps {
    baseFee: string;
    additionalUserFee: string;
}

export function Financed(props: FinancedProps): JSX.Element {
    return (
        <FAQModalContentWrapper>
            <h2>Was wird finanziert?</h2>
            <ul>
                <li>
                    <div>
                        <ArrowDoubleIcon />
                        <span>
                            Monatliche Lizenzgebühren für den SAD und die zusätzlich erstellten
                            Benutzer innerhalb einer Agentur
                        </span>
                    </div>
                    <ul>
                        <li>
                            <div>
                                <ArrowDoubleIcon />
                                <span>Hauptaccount: {props.baseFee} / Monat</span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <ArrowDoubleIcon />
                                <span>Je weiterer Benutzer: {props.additionalUserFee} / Monat</span>
                            </div>
                        </li>
                    </ul>
                </li>
                <li>
                    <div>
                        <ArrowDoubleIcon />
                        <span>
                            Zusätzlich erworbene Leistungen und Features werden nicht übernommen
                        </span>
                    </div>
                </li>
                <li>
                    <div>
                        <ArrowDoubleIcon />
                        <span>
                            SMS-Gebühren bei mehr als 100 SMS monatlich werden nicht übernommen
                        </span>
                    </div>
                </li>
                <li>
                    <div>
                        <ArrowDoubleIcon />
                        <span>Sammelcheck-und Widget-Gebühren werden nicht übernommen</span>
                    </div>
                </li>
            </ul>
        </FAQModalContentWrapper>
    );
}

const mapStateToProps = (state: IState) => {
    return {
        baseFee: selectBaseFee(state),
        additionalUserFee: selectAdditionalUserFee(state)
    };
};

export const FinancedWithStore = connect(mapStateToProps, {})(Financed);

import React from "react";
import styled from "styled-components";

const Input = styled.input`
    position: absolute;
    visibility: hidden;
    overflow: hidden;
    width: 0;
    height: 0;

    &:checked {
        + label {
            &:before {
                background-color: black;
                box-shadow: inset 0px 0px 0px 2px rgba(255, 255, 255, 1);
            }
        }
    }
`;

const RadioLabel = styled.label`
    position: relative;
    display: flex !important;
    align-items: center;
    cursor: pointer;
    margin-bottom: 8px;

    &:before {
        content: "";
        display: inline-block;
        width: 12px;
        height: 12px;
        border: 2px solid var(--primary);
        margin-right: 8px;
        flex-shrink: 0;
        border-radius: 50%;
    }
`;

export const RadioInput = React.forwardRef(({ id, name, label, ...rest }: any, ref: any) => {
    return (
        <>
            <Input ref={ref} id={id} type="radio" name={name} {...rest} />
            <RadioLabel htmlFor={id}>{label}</RadioLabel>
        </>
    );
});

import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { usePrevious } from "../../shared/hooks/use-previous";

export const ScrollToTop = withRouter(({ location }) => {
    const previousPathname = usePrevious(location.pathname);

    useEffect(() => {
        if (previousPathname !== location.pathname) {
            window.scrollTo(0, 0);
        }
    }, [previousPathname, location]);

    return <></>;
});

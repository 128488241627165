import { useRef, useState } from "react";

export function useModal() {
    const modalRef = useRef();
    const [isVisible, setIsVisible] = useState(false);

    function toggleVisibility() {
        setIsVisible(!isVisible);
    }

    return {
        modalRef,
        isVisible,
        toggleVisibility
    };
}

import React from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { VerificationFormWithStore } from "../components/Form/VerificationForm";
import { ReactComponent as Gothaer } from "../assets/images/gothaer.svg";
import styled from "styled-components";

const CenteredView = styled.div`
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    min-height: calc(100vh - 52px);
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const VerificationFormWrapper = styled.div`
    width: 100%;
    max-width: 630px;
    background-color: var(--white);
    padding: 100px;
`;

const LogoBar = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 100px 0;

    svg {
        width: 400px;
        height: 131px;
    }
`;

export const Verification = () => {
    return (
        <>
            <Container fluid>
                <CenteredView>
                    <LogoBar>
                        <Gothaer />
                    </LogoBar>
                    <VerificationFormWrapper>
                        <VerificationFormWithStore />
                    </VerificationFormWrapper>
                </CenteredView>
            </Container>
        </>
    );
};

export const VerificationWithStore = connect(null, {})(Verification);

import React, { useEffect, useReducer } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { IRegistrationData } from "../../shared/interfaces/registration-data.interface";
import { IState } from "../../shared/interfaces/state.interface";
import { register } from "../../shared/redux/app.api-middleware";
import {
    selectPreFillData,
    selectVerificationCode,
    selectVerificationEmail
} from "../../shared/redux/app.selectors";
import { Button, ButtonSpace, ButtonType } from "../shared/Button";
import {
    CheckboxInput,
    EmailInput,
    Fieldset,
    Form,
    FormElement,
    PasswordInput,
    TextInput
} from "../shared/Form";
import { RadioInput } from "../shared/Form/RadioInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { RegistrationValidationSchema } from "../../shared/validation-schema/registration.validation-schema";
import styled from "styled-components";
import { disableSubmitAction, enabledSubmitAction, submitReducer } from "./reducer/submit.reducer";
import { BoxTeaser } from "../Teaser/BoxTeaser/BoxTeaser";
import { Col, Row } from "react-bootstrap";
import { baseURL } from "../../shared/config/api.config";

const SubmitWrapper = styled.div`
    margin-top: 32px;

    button {
        margin-top: 16px;
    }
`;

const BoxTeaserWithMargin = styled(BoxTeaser)`
    margin-bottom: 32px;
    padding-top: 64px;
`;

const ColWithMargin = styled(Col)`
    margin-top: 20px;
`;

const FORM_DEFAULT_VALUES: IRegistrationData = {
    acceptedTermsAndPrivacyPolicy: false,
    acceptedOrderProcessingContract: false,
    email: "",
    password: "",
    company: {
        name: "",
        street: "",
        postcode: "",
        city: "",
        phone: "",
        website: ""
    },
    contactPerson: {
        gender: "",
        firstname: "",
        lastname: ""
    },
    billing: {
        accountOwner: "",
        iban: "",
        bic: ""
    },
    agencyCode: "",
    rdNumber: ""
};

interface IRegistrationFormProps {
    verificationCode: string;
    verificationEmail: string;
    preFillData: Partial<IRegistrationData>;
    register: (data: IRegistrationData, email: string, code: string) => Promise<void>;
}

export const RegistrationForm = (props: IRegistrationFormProps) => {
    const { verificationCode, verificationEmail, preFillData, register } = props;
    const methods = useForm<IRegistrationData>({
        mode: "onChange",
        defaultValues: FORM_DEFAULT_VALUES,
        resolver: yupResolver(RegistrationValidationSchema)
    });
    const [state, dispatch] = useReducer(submitReducer, { enabled: false });
    const { enabled } = state;

    const { control, formState, register: formRegister, errors, setValue } = methods;
    const { isValid } = formState;

    useEffect(() => {
        setValue("email", verificationEmail, { shouldDirty: false, shouldValidate: true });
        setValue("agencyCode", preFillData.agencyCode, {
            shouldDirty: false,
            shouldValidate: true
        });
        setValue("rdNumber", preFillData.rdNumber, { shouldDirty: false, shouldValidate: true });

        // Company
        setValue("company.name", preFillData.company?.name, {
            shouldDirty: false,
            shouldValidate: true
        });
        setValue("company.street", preFillData.company?.street, {
            shouldDirty: false,
            shouldValidate: true
        });
        setValue("company.postcode", preFillData.company?.postcode, {
            shouldDirty: false,
            shouldValidate: true
        });
        setValue("company.city", preFillData.company?.city, {
            shouldDirty: false,
            shouldValidate: true
        });
        setValue("company.phone", preFillData.company?.phone, {
            shouldDirty: false,
            shouldValidate: true
        });
        setValue("company.website", preFillData.company?.website, {
            shouldDirty: false,
            shouldValidate: true
        });

        // ContactPerson
        setValue("contactPerson.gender", preFillData.contactPerson?.gender, {
            shouldDirty: false,
            shouldValidate: true
        });
        setValue("contactPerson.firstname", preFillData.contactPerson?.firstname, {
            shouldDirty: false,
            shouldValidate: true
        });
        setValue("contactPerson.lastname", preFillData.contactPerson?.lastname, {
            shouldDirty: false,
            shouldValidate: true
        });
    }, [setValue, preFillData, verificationEmail]);

    useEffect(() => {
        if (isValid) {
            dispatch(enabledSubmitAction());
        } else {
            dispatch(disableSubmitAction());
        }
    }, [isValid]);

    const onSubmit = async (data: IRegistrationData) => {
        if (!verificationEmail || !verificationCode) {
            return;
        }

        dispatch(disableSubmitAction());

        const { agencyCode, rdNumber, ...rest } = data;

        await register(rest as IRegistrationData, verificationEmail, verificationCode);
    };

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={onSubmit}>
                    <BoxTeaserWithMargin>
                        <Fieldset>
                            <Fieldset.Legend>
                                <h4>Logindaten</h4>
                            </Fieldset.Legend>
                            <Fieldset.Content>
                                <Row>
                                    <Col xs={12} lg={6}>
                                        <FormElement
                                            label="E-Mail Adresse"
                                            required={true}
                                            error={errors.email}
                                        >
                                            <Controller
                                                control={control}
                                                name="email"
                                                readOnly={true}
                                                as={EmailInput}
                                            />
                                        </FormElement>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <FormElement label="Passwort" required={true}>
                                            <Controller
                                                control={control}
                                                name="password"
                                                as={<PasswordInput withError={true} />}
                                            />
                                        </FormElement>
                                    </Col>
                                </Row>
                            </Fieldset.Content>
                        </Fieldset>
                    </BoxTeaserWithMargin>
                    <BoxTeaserWithMargin>
                        <Fieldset>
                            <Fieldset.Legend>
                                <h4>Agenturdaten</h4>
                            </Fieldset.Legend>
                            <Fieldset.Content>
                                <Row>
                                    <Col xs={12} lg={6}>
                                        <FormElement
                                            label="Agenturnummer"
                                            required={true}
                                            error={errors?.agencyCode}
                                        >
                                            <Controller
                                                control={control}
                                                name="agencyCode"
                                                readOnly={!!preFillData.agencyCode}
                                                as={TextInput}
                                            />
                                        </FormElement>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <FormElement
                                            label="RD-Nummer"
                                            required={true}
                                            error={errors?.rdNumber}
                                        >
                                            <Controller
                                                control={control}
                                                name="rdNumber"
                                                readOnly={!!preFillData.rdNumber}
                                                as={TextInput}
                                            />
                                        </FormElement>
                                    </Col>
                                </Row>
                            </Fieldset.Content>
                        </Fieldset>
                    </BoxTeaserWithMargin>
                    <BoxTeaserWithMargin>
                        <Fieldset>
                            <Fieldset.Legend>
                                <h4>Firmendaten</h4>
                            </Fieldset.Legend>
                            <Fieldset.Content>
                                <Row>
                                    <Col xs={12} lg={6}>
                                        <FormElement
                                            label="Firmenname"
                                            required={true}
                                            error={errors.company?.name}
                                        >
                                            <Controller
                                                control={control}
                                                name="company.name"
                                                as={TextInput}
                                            />
                                        </FormElement>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} lg={4}>
                                        <FormElement
                                            label="Straße"
                                            required={true}
                                            error={errors.company?.street}
                                        >
                                            <Controller
                                                control={control}
                                                name="company.street"
                                                as={TextInput}
                                            />
                                        </FormElement>
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <FormElement
                                            label="Postleitzahl"
                                            required={true}
                                            error={errors.company?.postcode}
                                        >
                                            <Controller
                                                control={control}
                                                name="company.postcode"
                                                as={TextInput}
                                            />
                                        </FormElement>
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <FormElement
                                            label="Stadt"
                                            required={true}
                                            error={errors.company?.city}
                                        >
                                            <Controller
                                                control={control}
                                                name="company.city"
                                                as={TextInput}
                                            />
                                        </FormElement>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <FormElement
                                            label="Telefonnummer"
                                            required={true}
                                            error={errors.company?.phone}
                                        >
                                            <Controller
                                                control={control}
                                                name="company.phone"
                                                as={TextInput}
                                            />
                                        </FormElement>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <FormElement
                                            label="Website"
                                            required={true}
                                            error={errors.company?.website}
                                        >
                                            <Controller
                                                control={control}
                                                name="company.website"
                                                as={TextInput}
                                            />
                                        </FormElement>
                                    </Col>
                                </Row>
                            </Fieldset.Content>
                        </Fieldset>
                    </BoxTeaserWithMargin>
                    <BoxTeaserWithMargin>
                        <Fieldset>
                            <Fieldset.Legend>
                                <h4>Kontaktperson</h4>
                            </Fieldset.Legend>
                            <Fieldset.Content>
                                <FormElement
                                    label="Anrede"
                                    required={true}
                                    isRadioElement={true}
                                    error={errors.contactPerson?.gender}
                                >
                                    <RadioInput
                                        name="contactPerson.gender"
                                        id="female"
                                        value="female"
                                        label="Frau"
                                        ref={formRegister}
                                    />
                                    <RadioInput
                                        name="contactPerson.gender"
                                        id="male"
                                        value="male"
                                        label="Herr"
                                        ref={formRegister}
                                    />
                                </FormElement>
                                <Row>
                                    <Col xs={12} lg={6}>
                                        <FormElement
                                            label="Vorname"
                                            required={true}
                                            error={errors.contactPerson?.firstname}
                                        >
                                            <Controller
                                                control={control}
                                                name="contactPerson.firstname"
                                                as={TextInput}
                                            />
                                        </FormElement>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <FormElement
                                            label="Nachname"
                                            required={true}
                                            error={errors.contactPerson?.lastname}
                                        >
                                            <Controller
                                                control={control}
                                                name="contactPerson.lastname"
                                                as={TextInput}
                                            />
                                        </FormElement>
                                    </Col>
                                </Row>
                            </Fieldset.Content>
                        </Fieldset>
                    </BoxTeaserWithMargin>
                    <BoxTeaserWithMargin>
                        <Fieldset>
                            <Fieldset.Legend>
                                <h4>Kontodaten</h4>
                            </Fieldset.Legend>
                            <Fieldset.Content>
                                <Row>
                                    <Col xs={12} lg={6}>
                                        <FormElement
                                            label="Kontoinhaber"
                                            required={true}
                                            error={errors.billing?.accountOwner}
                                        >
                                            <Controller
                                                control={control}
                                                name="billing.accountOwner"
                                                as={TextInput}
                                            />
                                        </FormElement>
                                    </Col>
                                    <Col xs={12} lg={8}>
                                        <FormElement
                                            label="IBAN"
                                            required={true}
                                            error={errors.billing?.iban}
                                        >
                                            <Controller
                                                control={control}
                                                name="billing.iban"
                                                as={TextInput}
                                            />
                                        </FormElement>
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <FormElement
                                            label="BIC (optional)"
                                            error={errors.billing?.bic}
                                        >
                                            <Controller
                                                control={control}
                                                name="billing.bic"
                                                placeholder="optional"
                                                as={TextInput}
                                            />
                                        </FormElement>
                                    </Col>
                                    <ColWithMargin xs={12}>
                                        <FormElement
                                            required={true}
                                            error={errors.acceptedTermsAndPrivacyPolicy}
                                        >
                                            <Controller
                                                control={control}
                                                name="acceptedTermsAndPrivacyPolicy"
                                                id="acceptedTermsAndPrivacyPolicy"
                                                as={
                                                    <CheckboxInput>
                                                        <>
                                                            Mit der Bestätigung der Buchung gelten
                                                            der{" "}
                                                            <a
                                                                href={`${baseURL}assets/Rahmenvertrag.pdf`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                download
                                                            >
                                                                Rahmenvertrag
                                                            </a>
                                                            , unsere{" "}
                                                            <a
                                                                href="https://www.flixcheck.de/datenschutz/"
                                                                title="Datenschutzerklärung ansehen"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                Datenschutzerklärung
                                                            </a>{" "}
                                                            und unsere{" "}
                                                            <a
                                                                href={`${baseURL}assets/Sonderkonditionen.pdf`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                Sonderkonditionen
                                                            </a>{" "}
                                                            für Gothaer Außendienstmitarbeiter.
                                                        </>
                                                    </CheckboxInput>
                                                }
                                            />
                                        </FormElement>
                                        <FormElement
                                            required={true}
                                            error={errors.acceptedOrderProcessingContract}
                                        >
                                            <Controller
                                                control={control}
                                                name="acceptedOrderProcessingContract"
                                                id="acceptedOrderProcessingContract"
                                                as={
                                                    <CheckboxInput>
                                                        <>
                                                            Mit der Bestätigung der Buchung
                                                            schließen Sie zudem einen{" "}
                                                            <a
                                                                href={`${baseURL}assets/AVV.pdf`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                Vertrag zur Auftragsverarbeitung
                                                            </a>{" "}
                                                            mit der Flixcheck GmbH ab.
                                                        </>
                                                    </CheckboxInput>
                                                }
                                            />
                                        </FormElement>
                                    </ColWithMargin>
                                </Row>
                            </Fieldset.Content>
                        </Fieldset>
                    </BoxTeaserWithMargin>
                    <SubmitWrapper>
                        <Button
                            label="Registrieren"
                            disabled={!enabled}
                            space={ButtonSpace.Big}
                            type={ButtonType.Submit}
                        />
                    </SubmitWrapper>
                </Form>
            </FormProvider>
        </>
    );
};

const mapStateToProps = (state: IState) => {
    return {
        verificationCode: selectVerificationCode(state),
        verificationEmail: selectVerificationEmail(state),
        preFillData: selectPreFillData(state)
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        register: (data: IRegistrationData, email: string, code: string) =>
            dispatch(register(data, email, code))
    };
};

export const RegistrationFormWithStore = connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrationForm);

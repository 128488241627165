import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import Logo from "../../assets/images/logo.svg";

const StyledContainer = styled(Container)`
    max-width: 1440px;
`;

const NavContainer = styled.div`
    width: 100%;
    position: relative; // IE Fallback
    position: sticky;
    top: 0;
    background-color: var(--white);
    display: flex;
    justify-content: center;
    z-index: 1000;
`;

const StyledNav = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 31px 0;
`;

const StyledNavigation = styled.ul`
    display: flex;
    list-style-type: none;

    li {
        margin-left: 48px;

        &:first-child {
            margin-left: 0;
        }

        a {
            color: var(--black);
            font-size: 18px;
            font-weight: 700;
            text-decoration: none;
        }
    }
`;

export const Navigation = () => {
    return (
        <NavContainer>
            <StyledContainer fluid>
                <Row>
                    <Col xs={12}>
                        <StyledNav>
                            <a
                                href="https://www.flixcheck.de/"
                                title="Digitale Formulare, bessere Kundenkommunikation | Flixcheck"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={Logo} alt="Flixcheck GmbH" />
                            </a>
                            <StyledNavigation>
                                <li>
                                    <a
                                        href="https://www.flixcheck.de/"
                                        title="Digitale Formulare, bessere Kundenkommunikation | Flixcheck"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Unternehmen
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.flixcheck.de/"
                                        title="Digitale Formulare, bessere Kundenkommunikation | Flixcheck"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Kontakt
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.flixcheck.de/"
                                        title="Digitale Formulare, bessere Kundenkommunikation | Flixcheck"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Anmelden
                                    </a>
                                </li>
                            </StyledNavigation>
                        </StyledNav>
                    </Col>
                </Row>
            </StyledContainer>
        </NavContainer>
    );
};

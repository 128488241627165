import { useEffect } from "react";

export function useClickOutside(
    ref: React.MutableRefObject<HTMLElement>,
    callback: (...props: any) => void
) {
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref?.current?.contains(event.target)) {
                callback(event);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, callback]);
}

import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { Header, NavigationSimple } from "../components/Header";
import { Section } from "../components/shared/Section";
import { StyledLink } from "../components/shared/Button/StyledLink";
import { VideoTeaser } from "../components/Teaser/VideoTeaser/VideoTeaser";
import { ExampleOfUseTeaser } from "../components/Teaser/ExampleOfUseTeaser/ExampleOfUseTeaser";
import { CtaTeaser } from "../components/Teaser/CtaTeaser/CtaTeaser";
import { StickyFooter } from "../components/shared/Footer";
import { ContainerWithMaxWidth } from "../components/shared/ContainerWithMaxWidth";

const StyledSection = styled(Section)`
    background-color: var(--white);
    padding: 30px 100px 135px 100px;
    margin-right: -16px;
    margin-top: -115px;
`;

const StyledLinkWithMargin = styled(StyledLink)`
    margin-top: 60px;
`;

const Main = styled.main`
    background-color: var(--white);
`;

export const Index = () => {
    return (
        <Main>
            <NavigationSimple />
            <Header />
            <ContainerWithMaxWidth>
                <Row>
                    <Col xs={12} lg={{ span: 9, offset: 3 }}>
                        <StyledSection>
                            <h1>Der Flixcheck-Sondertarif</h1>
                            <p>
                                In Kooperation mit der Gothaer Zentrale machen wir dem
                                Exklusivvertrieb ein besonders attraktives Angebot für die Nutzung
                                von Flixcheck. Klicken Sie auf den untenstehenden Button für Ihren
                                Sondertarif und melden Sie sich ganz einfach an.
                            </p>
                            <StyledLinkWithMargin to="/verification">
                                Jetzt Registrieren
                            </StyledLinkWithMargin>
                        </StyledSection>
                    </Col>
                </Row>
                <VideoTeaser />
                <ExampleOfUseTeaser />
                <CtaTeaser />
            </ContainerWithMaxWidth>
            <StickyFooter />
        </Main>
    );
};

export function hasLowerCaseCharacters(value: any) {
    return value.match(/[a-z]/g);
}

export function hasUpperCaseCharacters(value: any) {
    return value.match(/[A-Z]/g);
}

export function hasSpecialCharacters(value: any) {
    return value.match(/[^A-Za-z0-9]/g);
}

export function validatePhoneNumber(value: any) {
    return value.match(/^\+?[0-9\s]*$/);
}

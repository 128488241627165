import React from "react";
import { Container } from "react-bootstrap";
interface IContainerWithMaxWidthProps {
    className?: string;
    children?: any;
}

export const ContainerWithMaxWidth = (props: IContainerWithMaxWidthProps) => {
    const { className, children } = props;

    return <Container className={className}>{children}</Container>;
};

import React from "react";
import styled from "styled-components";

const Teaser = styled.div`
    width: 100%;
    padding: 30px;
    background-color: var(--white);
`;

interface IBoxTeaserProps {
    className?: string;
    children?: any;
}

export const BoxTeaser = (props: IBoxTeaserProps) => {
    const { className, children } = props;

    return <Teaser className={className}>{children}</Teaser>;
};

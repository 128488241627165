import React from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../../../assets/images/logo_small.svg';

const StyledLogo = styled.img`
    width: 104px;
    height: auto;
`;

const SubNavigation = styled.ul`
    padding-left: 20px;
    list-style-type: none;
    display: flex;
    
    li {
        padding: 0 8px;

        &:first-child {
            padding: 0 8px 0px 0px;
        }

        a {
            text-decoration: none;
            font-weight: 800;

            &:hover {
                color: var(--secondary);
            }
        }
    }
`;

const StyledContainer = styled(Container)`
    max-width: 900px;
`;

const StyledFooter = styled.footer`
    margin-top: 64px;
    padding: 64px 0;
    background-color: var(--primary);
`

export const Footer = () => {
    return (
        <StyledFooter>
            <StyledContainer>
                <Row>
                    <Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} xl={{ span: 12, offset: 0 }}>
                        <Row>
                        <Col xs={12}>
                            <StyledLogo src={Logo} alt="Flixcheck GmbH" />
                        </Col>
                        <Col xs={12}>
                            <SubNavigation>
                                <li>
                                    <a href='https://www.flixcheck.de/impressum/' target='_blank' rel="noreferrer">
                                        Impressum
                                    </a>
                                </li>
                                <li>
                                    <a href='https://www.flixcheck.de/agb/' target='_blank' rel="noreferrer">
                                        AGB
                                    </a>
                                </li>
                                <li>
                                    <Link to='/conditions'>
                                        Sonderkonditionen
                                    </Link>
                                </li>
                                <li>
                                    <a href='https://www.flixcheck.de/datenschutz/' target='_blank' rel="noreferrer">
                                        Datenschutz
                                    </a>
                                </li>
                            </SubNavigation>
                        </Col>
                    </Row>
                    </Col>
                </Row>
            </StyledContainer>
        </StyledFooter>
    )
};
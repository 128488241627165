import React from "react";
import { ReactComponent as ArrowDoubleIcon } from "../../../assets/images/icons/arrow_double.svg";
import { FAQModalContentWrapper } from "./FAQModalContentWrapper";
import { useCurrentDate } from "../../../shared/hooks/use-current-date";
import { CONFIG } from "../../../shared/config/config";

const EXAMPLE_CALCULATION_MONTHS = 9;

export function SpecialRules(): JSX.Element {
    const { currentYear } = useCurrentDate();

    const totalChecksForExample = Math.round(CONFIG.checksPerMonth * EXAMPLE_CALCULATION_MONTHS);

    return (
        <FAQModalContentWrapper>
            <h2>Sonderregeln & weitere Informationen</h2>
            <ul>
                <li>
                    <div>
                        <ArrowDoubleIcon />
                        <span>
                            Das Ziel von {CONFIG.checksPerYear} Checks gilt für die Agenturen, die
                            im Januar bereits registriert sind
                        </span>
                    </div>
                </li>
                <li>
                    <div>
                        <ArrowDoubleIcon />
                        <span>
                            Agenturen, die sich im laufenden Jahr {currentYear} registrieren, müssen
                            das Ziel für die anstehenden Monate erreichen.
                        </span>
                    </div>
                    <ul>
                        <li>
                            <div>
                                <ArrowDoubleIcon />
                                <span>
                                    Bsp: Registrierung am 01.04.{currentYear}; Ziel:{" "}
                                    {CONFIG.checksPerMonth} Checks x {EXAMPLE_CALCULATION_MONTHS}{" "}
                                    Monate = {totalChecksForExample} Checks
                                </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <ArrowDoubleIcon />
                                <span>
                                    Das Monatsziel von {CONFIG.checksPerMonth} Checks gilt für jeden
                                    Monat, in dem der Account registriert ist - unabhängig davon, ob
                                    der Account zum ersten des Monats oder zum 25. Tag eines Monats
                                    registriert wurde
                                </span>
                            </div>
                        </li>
                    </ul>
                </li>
                <li>
                    <div>
                        <ArrowDoubleIcon />
                        <span>
                            Das Angebot gilt nur für Agenturen, die unabhängig vom Starttermin bis
                            Ende {currentYear} einen Account halten
                        </span>
                    </div>
                </li>
            </ul>
        </FAQModalContentWrapper>
    );
}

import { IAction } from "../interfaces/action.interface";
import { IRegistrationData } from "../interfaces/registration-data.interface";
import { AppActionTypes } from "./app.action-types";

export function setLoadingStatusAction(status: boolean): IAction {
    return {
        type: AppActionTypes.SetLoadingStatus,
        payload: status
    };
}

export function setVerificationDataAction(email: string, code: string): IAction {
    return {
        type: AppActionTypes.SetVerificationData,
        payload: {
            code,
            email
        }
    };
}

export function setContractData(contractData: any): IAction {
    return {
        type: AppActionTypes.SetContractData,
        payload: contractData
    };
}

export function setSuccessFulRegistration(
    success: boolean,
    registrationData: IRegistrationData
): IAction {
    return {
        type: AppActionTypes.SetSuccessFulRegistration,
        payload: {
            successFulRegistration: success,
            registrationData
        }
    };
}

export function setLoginUrlAction(url: string): IAction {
    return {
        type: AppActionTypes.SetLoginUrl,
        payload: url
    };
}

export function SetCodeRequestedAction(): IAction {
    return {
        type: AppActionTypes.SetCodeRequested
    };
}

export function setPreFillDataAction(preFillData: Partial<IRegistrationData>): IAction {
    return {
        type: AppActionTypes.SetPreFillData,
        payload: preFillData
    };
}

import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { StyledLink } from "../../shared/Button/StyledLink";
import { Section } from "../../shared/Section";
import { ReactComponent as Gothaer } from "../../../assets/images/gothaer.svg";
import CtaImage from "../../../assets/images/cta-image.png";

const StyledSection = styled(Section)`
    padding: 0 0 120px;
`;

const LeftColumn = styled(Row)`
    background-color: var(--secondary);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;

    svg {
        width: 150px;
        height: auto;
        margin-bottom: 20px;
    }
`;

const RightColumn = styled(Row)`
    height: 100%;
    background-image: url(${CtaImage});
    background-size: cover;
    background-position: center;
`;

export const CtaTeaser = () => {
    return (
        <StyledSection>
            <Row>
                <Col xs={12} md={6}>
                    <LeftColumn>
                        <Gothaer />
                        <h3>Sondertarif sichern</h3>
                        <StyledLink to="/verification">Jetzt Registrieren</StyledLink>
                    </LeftColumn>
                </Col>
                <Col xs={12} md={6}>
                    <RightColumn />
                </Col>
            </Row>
        </StyledSection>
    );
};

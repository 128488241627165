import React from "react";
import styled from "styled-components";

interface ISpinnerProps {
    stroke?: string;
}

export const Spinner = (props: ISpinnerProps) => {
    const { stroke } = props;

    return (
        <StyledSpinner theme={{ stroke: stroke || "#ffffff" }} viewBox="0 0 50 50">
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
        </StyledSpinner>
    );
};

const StyledSpinner = styled.svg`
    animation: rotate 2s linear infinite;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    margin-left: -25px;
    margin-top: -25px;

    & .path {
        stroke: ${({ theme }) => theme.stroke};
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
`;

StyledSpinner.defaultProps = {
    theme: {
        stroke: "#ffffff"
    }
};

import React from "react";
import { useFormContext } from "react-hook-form";
interface IFormProps {
    children?: JSX.Element | JSX.Element[];
    onSubmit?: (data: any) => any;
    className?: string;
}

export const Form = React.memo((props: IFormProps) => {
    const { children, onSubmit, className } = props;
    const methods = useFormContext();
    const { handleSubmit } = methods;

    return (
        <form
            className={className}
            onSubmit={handleSubmit((data) => onSubmit?.(data))}
        >
            {children}
        </form>
    );
});

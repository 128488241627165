import { Link } from "react-router-dom";
import styled from "styled-components";
import { darken } from "polished";

export const StyledLink = styled(Link)`
    font-weight: 500;
    font-size: 24px;
    padding: 28px;
    display: inline-block;
    flex-shrink: 0;
    cursor: pointer;
    border: 0;
    background-color: #ed706c;
    color: var(--white);
    text-transform: uppercase;
    text-decoration: none;
    line-height: 1;
    transition: 0.15s ease-in-out;

    &:hover {
        background-color: ${darken(0.1, "#ED706C")};
        color: var(--white);
    }
`;
